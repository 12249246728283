body {
  margin: 0;
  font-family: "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.container {
  display: grid;
  grid-template-columns: minmax(200px, 230px) 2fr;
  grid-template-rows: auto;
  grid-template-areas:
    "main-header main-header"
    /* ". breadcrumbs" */
    "nav main";
}

.main-header {
  background-color: #f8f8f8;
  border-top: 1px solid #000;
  border-bottom: 1px solid #000;
  grid-area: main-header;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 30px;
}

.logo {
  width: 15%;
}

.logo img {
  width: 100%;
  padding: 30px;
}

.user img {
  width: 100px;
}

.sidebar {
  background-color: #112579 !important;
  grid-area: nav;
  border-right: 2px solid #000;
  margin: 0;
  display: flex;
  flex-direction: column;
  list-style-type: none;
  padding: 60px 0 0 0;
  font-family: "Roboto", sans-serif;
  letter-spacing: 1px;
  height: 100vh;
  max-width: 240px;
}

.sidebar a {
  color: #fff !important;
  text-decoration: none;
  font-size: 16px;
  padding: 25px 30px 25px 30px;
  transition: all 0.8s ease;
  display: flex;
  margin-bottom: 20px;
  border-radius: 50px;
}

.sidebar a:hover,
.sidebar .active {
  background-color: #f8f8f8;
  color: #000 !important;
  box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.6);
}

ul.sidebar {
  padding-left: 0px !important;
  list-style: none;
  margin-right: 0px !important;
}

.sidebar li:hover {
  cursor: pointer;
}

.sidebar i {
  padding-right: 10px;
}

.fa-caret-down {
  padding-left: 60%;
}

.fa-ticket-alt {
  display: flex;
  align-items: right;
}

#dropdown a {
  font-size: 12px;
  padding: 20px 25px 20px 25px;
  width: 80%;
}

.breadcrumbs {
  grid-area: breadcrumbs;
}

.main {
  background-color: #fff;
  grid-area: main;
  height: 100vh;
}

.ticket-form {
  margin: 80px;
  width: 62%;
}

.form-title {
  margin-bottom: 40px;
}

label span {
  margin-right: 10px;
}

.kbSearchBarItems {
  margin-bottom: 40px;
  background-color: #efefef;
  border: 1px solid #000;
  padding: 60px;
  border-radius: 5px;
}

.nature {
  margin-bottom: 20px;
}

.nature label {
  width: 100%;
}

.contactType {
  margin-bottom: 20px;
}

.contactType label {
  width: 100%;
}

.dateReported {
  margin-bottom: 20px;
}

.dateReported label {
  width: 100%;
}

.ticketFormItems {
  margin-bottom: 40px;
}

.ticketName {
  margin-bottom: 20px;
}
.ticketName input {
  width: 100%;
}

.editableTicketFormsItems .companyName {
  width: 100%;
}

.companyName {
  margin-bottom: 20px;
  margin-top: 40px;
}

.companyName input {
  width: 100%;
}

.addCompanyBtn {
  background-color: #112579;
  color: #fff;
  border: 1px solid #112579;
  padding: 5px 15px;
  height: 42px;
}

.siteAddress {
  margin-bottom: 20px;
}

.siteAddress input {
  width: 100%;
}

.sitePostcode {
  margin-bottom: 20px;
}

.sitePostcode input {
  width: 100%;
}

.clientName {
  margin-bottom: 20px;
}

.clientName input {
  width: 100%;
}

.clientEmail {
  margin-bottom: 20px;
}

.clientEmail input {
  width: 100%;
}

.clientNumber {
  margin-bottom: 20px;
}

.clientNumber input {
  width: 100%;
}

.createdName {
  margin-bottom: 20px;
}

.createdName label {
  width: 100%;
}

.allocatedBusiness {
  margin-bottom: 35px;
  margin-top: 50px;
}

.allocatedBusiness label {
  width: 100%;
}

.ticketResolved {
  margin-bottom: 40px;
}

.allocatedStaff {
  margin-bottom: 20px;
}

.allocatedStaff input {
  width: 100%;
}

.editableTicketFormsItems input {
  outline: none;
  border: none;
  font-size: 15px;
  color: #000;
  padding: 8px 8px;
}

.editableTicketFormsItems input:hover {
  background-color: #fbfbfb;
  color: #000;
  transition: all ease 0.8s;
}

.editableTicketFormsItems textarea {
  outline: none;
  border: none;
  font-size: 15px;
  color: #000;
  padding: 8px 8px;
}

.editableTicketFormsItems textarea:hover {
  background-color: #fbfbfb;
  color: #000;
  transition: all ease 0.8s;
}

.ticketFormItems .additionalNotes {
  margin-bottom: 35px;
}

.ticketPrioritised {
  margin-top: 20px;
  margin-bottom: 35px;
}

.ticketPrioritised span {
  margin-right: 10px;
}

.status {
    margin-top: 20px;
    margin-bottom: 35px;
}

.status label {
  width: 100%;
}

.state {
  margin-bottom: 50px;
}

.state label {
  width: 100%;
}

.ticketFormItems .files input {
  border: none !important;
}

.files {
  margin-top: 40px;
  margin-bottom: 30px;
}

.ticketFormItems .row13 .item2 {
  flex-grow: 1;
}

.ticketFormItems #submit {
  padding: 15px 10px;
  background-color: #112579;
  border: 1px solid #112579;
  border-radius: 0px;
  color: #fff;
  transition: all 0.8s ease;
  font-weight: bold;
  cursor: pointer;
  margin: 10px;
  margin-left: 0;
  width: 50%;
}

.ticketFormItems #submit:hover {
  background-color: #f8f8f8;
  border: 1px solid #000;
  color: #000;
  cursor: pointer;
}

.ticketFormItems #reset {
  padding: 15px 10px;
  background-color: #555;
  border: none;
  border-radius: 0px;
  border: 1px solid #000;
  color: #fff;
  transition: all 0.8s ease;
  font-weight: bold;
  cursor: pointer;
  margin: 10px;
  margin-right: 0;
  width: 50%;
}

.ticketFormItems #reset:hover {
  background-color: #222;
  border: 1px solid #000;
  color: #fff;
  cursor: pointer;
}

.kb-form {
  display: flex;
  flex-direction: column;
  margin: 50px;
  width: 80%;
}

.kb-form #kb-form-title {
  margin-bottom: 40px;
}

.kb-form .kb-row1 {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.kb-form .kb-row1 input {
  padding: 8px 8px;
  width: 500px;
  margin-top: 10px;
}

.kb-form .kb-row5 {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.kb-form .kb-row5 input {
  padding: 8px 8px;
  width: 500px;
  margin-top: 10px;
}

.kb-form .kb-row2 {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.kb-form .kb-row2 input {
  padding: 8px 8px;
  width: 100%;
  margin-top: 10px;
}

.kb-form .kb-row3 {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.kb-form .kb-row3 textarea {
  width: 100%;
  margin-top: 10px;
  height: 400px;
  font-family: "Segoe UI";
}

.kb-form .kb-row4 {
  margin-bottom: 20px;
}

.kb-form #submit {
  padding: 15px 10px;
  background-color: #112579;
  border: 1px solid #112579;
  border-radius: 0px;
  color: #fff;
  transition: all 0.8s ease;
  font-weight: bold;
  cursor: pointer;
  width: 30%;
}

.kb-form #submit:hover {
  background-color: #f8f8f8;
  border: 1px solid #000;
  color: #000;
  cursor: pointer;
}

.createKBForm {
  margin: 80px;
  width: 80%;
}

.createKBFormItems {
  margin-bottom: 40px;
}

.createKBFormItems textarea {
  padding: 10px 10px;
  font-size: 14px;
}

.createKBFormItems .row5 input {
  margin-top: 40px;
  border: none !important;
}

.createKBFormTitle {
  margin-bottom: 40px;
}

.kbName {
  margin-bottom: 40px;
}

.kbName input {
  width: 100%;
}

.kbTopic {
  margin-bottom: 40px;
}

.kbTopic input {
  width: 100%;
}

.kbProblem {
  margin-bottom: 40px;
}

.kbProblem input {
  width: 100%;
}

.createKBFormItems .row4 textarea {
  height: 400px;
  margin-top: 10px;
  font-family: "Segoe UI";
  width: 100%;
  margin-bottom: 20px;
}

.createKBFormItems #submit {
  padding: 15px 10px;
  background-color: #112579;
  border: 1px solid #112579;
  border-radius: 0px;
  color: #fff;
  transition: all 0.8s ease;
  font-weight: bold;
  cursor: pointer;
  margin: 10px;
  margin-left: 0;
  width: 45%;
}

.createKBFormItems #submit:hover {
  background-color: #f8f8f8;
  border: 1px solid #000;
  color: #000;
  cursor: pointer;
}

#tickets {
  margin: 80px;
}

#tickets h1 {
  font-family: "Segoe Ui", sans-serif;
  font-weight: 300;
  letter-spacing: 1px;
}

#tickets hr {
  width: 20%;
  text-align: left;
  margin-bottom: 40px;
}

.createNewBtn {
  background-color: #112579;
  color: #fff;
  padding: 15px 15px;
  border: 1px solid #000;
  cursor: pointer;
  transition: all ease 0.8s;
  border-radius: 5px;
}

.createNewBtn i {
  padding-left: 10px;
}

.createNewBtn:hover {
  background-color: #fff;
  color: #000;
}

#ticketsTable {
  margin-top: 30px;
}

#ticketsTable i {
  color: #0080ff;
  font-size: 18px;
  text-align: center;
}

#userTable {
  margin-top: 30px;
}

#userTable i {
  color: #f00;
  font-size: 18px;
}

.table-menu {
  background-color: transparent;
  min-width: 115px;
  display: flex;
  padding-left: 0px;
  margin-left: 0px;
}

.dashboardTickets {
  margin: 80px;
}

h1 {
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  letter-spacing: 1px;
  font-size: 26px;
  text-transform: uppercase;
}

h2 {
  font-size: 18px;
  font-family: "Roboto", sans-serif;
  text-transform: uppercase;
  font-weight: 400;
  text-align: center;
}

.dashboardTickets hr {
  width: 20%;
  text-align: left;
}

.dashboardTicketsRow {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

.dashboardTicketsRow div {
  min-width: 160px;
}

.rr-dashboard-col {
  display: flex;
  flex-direction: column;
  margin: 10px;
}

.rr-dashboard-col span {
  background-color: #e02c2c;
  text-align: center;
  font-size: 70px;
  font-weight: bold;
  color: #fff;
  padding: 10px 30px;
  border: 1px solid #000;
  border-bottom: none;
}

.agile-dashboard-col {
  display: flex;
  flex-direction: column;
  margin: 10px;
}

.agile-dashboard-col span {
  background-color: #01285c;
  text-align: center;
  font-size: 70px;
  font-weight: bold;
  color: #fff;
  padding: 10px 30px;
  border: 1px solid #000;
  border-bottom: none;
}

.stratus-dashboard-col {
  display: flex;
  flex-direction: column;
  margin: 10px;
}

.stratus-dashboard-col span {
  background-color: #488ecc;
  text-align: center;
  font-size: 70px;
  font-weight: bold;
  color: #fff;
  padding: 10px 30px;
  border: 1px solid #000;
  border-bottom: none;
}

.tcs-dashboard-col {
  display: flex;
  flex-direction: column;
  margin: 10px;
}

.tcs-dashboard-col span {
  background-color: #722b71;
  text-align: center;
  font-size: 70px;
  font-weight: bold;
  color: #fff;
  padding: 10px 30px;
  border: 1px solid #000;
  border-bottom: none;
}

.unitygtm-dashboard-col {
  display: flex;
  flex-direction: column;
  margin: 10px;
}

.unitygtm-dashboard-col span {
  background-color: #000;
  text-align: center;
  font-size: 70px;
  font-weight: bold;
  color: #fff;
  padding: 10px 30px;
  border: 1px solid #000;
  border-bottom: none;
}

.qnet-dashboard-col {
  display: flex;
  flex-direction: column;
  margin: 10px;
}

.qnet-dashboard-col span {
  background-color: #ec7034;
  text-align: center;
  font-size: 70px;
  font-weight: bold;
  color: #fff;
  padding: 10px 30px;
  border: 1px solid #000;
  border-bottom: none;
}

.brunel-dashboard-col {
  display: flex;
  flex-direction: column;
  margin: 10px;
}

.brunel-dashboard-col span {
  background-color: #722b71;
  text-align: center;
  font-size: 70px;
  font-weight: bold;
  color: #fff;
  padding: 10px 30px;
  border: 1px solid #000;
  border-bottom: none;
}

.viewTicketsbtn {
  background-color: #f8f8f8;
  color: #000 !important;
  cursor: pointer;
  padding: 8px 4px;
  border: 1px solid #000;
  align-items: center;
  font-family: "Segoe UI";
  transition: all ease 0.6s;
  font-size: 12px;
  font-family: "Roboto", sans-serif;
}

.viewTicketsbtn:hover {
  background-color: #e4e4e4;
}

.completedTickets {
  margin: 80px;
}

.completedTickets h1 {
  font-family: "Segoe Ui", sans-serif;
  font-weight: 300;
  letter-spacing: 1px;
}

.completedTickets hr {
  width: 20%;
}

.completedTicketsRow {
  display: flex;
  flex-direction: row;
}

.completedTableMenu,
.MuiListItemText-root {
  font-size: 20px;
}

g text {
  font-family: "Roboto", sans-serif;
  text-transform: uppercase;
}

.submittedKBForm {
  margin: 80px;
  width: 80%;
}

.submittedKBFormTitle {
  margin-bottom: 40px;
}

.knowledgeBaseOutput {
  margin-right: 5px;
  margin-bottom: 10px;
  font-weight: 300;
}

.submittedBFormItems {
  margin-bottom: 40px;
}

.submittedBFormItems .row1 {
  display: flex;
  margin-bottom: 20px;
  justify-content: space-between;
}

.submittedBFormItems .row2 {
  display: flex;
  flex-wrap: wrap;
  margin-top: 25px;
  margin-bottom: 20px;
  width: 100% !important;
}

.submittedBFormItems .row3 {
  margin-top: 25px;
  margin-bottom: 40px;
}

.submittedBFormItems .row4 {
}

.submittedBFormItems #submit {
  padding: 15px 10px;
  background-color: #112579;
  border: 1px solid #112579;
  border-radius: 0px;
  color: #fff;
  transition: all 0.8s ease;
  font-weight: bold;
  cursor: pointer;
  width: 50%;
}

.submittedBFormItems #submit:hover {
  background-color: #f8f8f8;
  border: 1px solid #000;
  color: #000;
  cursor: pointer;
}

.editableKBForm {
  margin: 80px;
  width: 80%;
}

.editableKBFormTitle {
  margin-bottom: 40px;
}

.editableKBFormItems input {
  padding: 10px 10px;
}

.editableKBFormItems .row1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
  flex-wrap: wrap;
  justify-content: space-between;
}

.editableKBFormItems .row1 .item2 {
  flex-grow: 1;
  margin-right: 5%;
}

.editableKBFormItems .row1 .item4 {
  flex-grow: 1;
}

.editableKBFormItems .row2 {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
  flex-wrap: wrap;
}

.editableKBFormItems .row2 .item2 {
  flex-grow: 1;
}

/* .editableKBFormItems .row4 textarea {
  height: 400px;
  margin-top: 10px;
  font-family: "Segoe UI";
  width: 100%;
  margin-bottom: 20px;
  padding: 10px;
} */

.editableKBFormItems #submit {
  padding: 15px 10px;
  background-color: #112579;
  border: 1px solid #112579;
  border-radius: 0px;
  color: #fff;
  transition: all 0.8s ease;
  font-weight: bold;
  cursor: pointer;
  margin: 10px;
  margin-left: 0;
  width: 45%;
}

.editableKBFormItems #submit:hover {
  background-color: #f8f8f8;
  border: 1px solid #000;
  color: #000;
  cursor: pointer;
}

.editableKBFormItems input {
  outline: none;
  border: none;
  font-size: 15px;
  color: #000;
  padding: 8px 8px;
}

.editableKBFormItems label {
  margin-bottom: -10px;
}

.editableKBFormItems input:hover {
  color: #000;
  transition: all ease 0.8s;
}

.editableKBFormItems input:focus {
  background-color: #efefef;
  color: #000;
}

.editableKBFormItems textarea {
  outline: none;
  font-size: 15px;
  color: #000;
}

/* .editableKBFormItems textarea:hover {
  background-color: #fbfbfb;
  color: #000;
  transition: all ease 0.8s;
} */

.editableKBFormItems textarea:focus {
  background-color: #efefef;
  transition: all ease 0.8s;
  color: #000;
}

.editableKBFormItems .row5 {
  margin-bottom: 60px;
  margin-top: 40px;
}

#submittedForm {
  margin: 80px;
  width: 75%;
}

.submittedTicketItems {
  margin-bottom: 40px;
}

#submittedForm span {
  display: inline-block;
  font-weight: 300;
  margin-right: 10px;
}

.submittedTicketOutput {
  margin-right: 5px;
  margin-bottom: 10px;
}

.submittedForm-title {
  margin-bottom: 40px;
}

#submittedForm .row1 {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;
  justify-content: space-between;
}

#submittedForm .item2 {
  margin-left: 10px;
}

#submittedForm .row2 {
  margin-top: 25px;
  margin-bottom: 10px;
}

#submittedForm .row3 {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;
  margin-top: 25px;
}

#submittedForm .row4 {
  display: flex;
  margin-bottom: 10px;
  justify-content: space-between;
  margin-top: 25px;
}

#submittedForm .row5 {
  margin-bottom: 10px;
  margin-top: 25px;
}

#submittedForm .row6 {
  display: flex;
  margin-bottom: 10px;
  margin-top: 25px;
  justify-content: space-between;
}

#submittedForm .row7 {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  margin-top: 25px;
}

#submittedForm .row7 {
  margin-bottom: 10px;
}

#submittedForm .row8 {
  margin-bottom: 10px;
  margin-top: 25px;
}

#submittedForm .row9 {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  margin-top: 25px;
}

#companies {
  margin: 80px;
}

#companies input {
  font-size: 14px;
}

#companies h1 {
  font-family: "Segoe Ui", sans-serif;
  font-weight: 300;
  letter-spacing: 1px;
}

#companies hr {
  width: 20%;
  text-align: left;
  margin-bottom: 40px;
}

#companiesTable {
  margin-top: 30px;
}

.addCompanyRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
}

.addCompany {
  flex-grow: 1;
}

.addCompanyRow input {
  border: none;
}

.tableBtn {
  background-color: #112579;
  color: #fff;
  padding: 15px 15px;
  border: 1px solid rgb(77, 74, 74);
  cursor: pointer;
  transition: all ease 0.8s;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
}

.tableBtn:hover {
  background-color: #f8f8f8;
  border: 1px solid #000;
  color: #000;
  cursor: pointer;
}

.uploadCompanyRowBtn {
  display: flex;
  flex-direction: row;
}

.createCompanyForm {
  margin: 80px;
  width: 80%;
}

input {
  background-color: #fff;
  padding: 10px 10px;
  border: 0.5px solid rgba(0, 0, 0, 0.3);
  font-size: 14px;
  font-family: "Segoe UI", sans-serif;
}

select {
  font-size: 14px;
  font-family: "Segoe UI", sans-serif;
  padding: 8px 8px;
  width: 200px !important;
}

textarea {
  padding: 15px;
  font-size: 14px;
  width: 100%;
  height: 200px !important;
}

.createCompanyForm input:hover {
  background-color: #fff;
  transition: all ease 0.8s;
}

.createCompanyForm input:focus {
  background-color: #f9f9f9;
}

.formBackground {
  margin-bottom: 40px;
  background-color: #fdfdfd;
  border: 0.5px solid rgba(0, 0, 0, 0.3);
  padding: 60px;
}

.nameAndBusiness {
  margin-bottom: 20px;
}

.nameAndBusiness input {
  width: 100%;
}

.reg {
  margin-bottom: 20px;
}

.reg input {
  width: 100%;
}

.sla {
  margin-bottom: 20px;
}

.sla input {
  width: 100%;
}

.firstLine {
  margin-bottom: 20px;
  margin-top: 10px;
}

.firstLine input {
  width: 100%;
}

.secondLine {
  margin-bottom: 20px;
}

.secondLine input {
  width: 100%;
}

.town {
  margin-bottom: 20px;
}

.town input {
  width: 100%;
}

.postcode {
  margin-bottom: 20px;
}

.postcode input {
  width: 100%;
}


.staffName {
  margin-bottom: 20px;
}

.staffName input {
  width: 100%;
}

.staffSecondName {
  margin-bottom: 20px;
}

.staffSecondName input {
  width: 100%;
}

.staffRole {
  margin-bottom: 20px;
}

.staffRole input {
  width: 100%;
}

.staffNumber {
  margin-bottom: 20px;
}

.staffNumber input {
  width: 100%;
}

.staffEmail {
  margin-bottom: 20px;
}

.staffEmail input {
  width: 100%;
}

.addStaffButton {
  margin-bottom: 50px;
  cursor: pointer;
}

.removeStaffButton {
  margin-bottom: 50px;
  cursor: pointer;
  color: #f00;
  display: flex;
  justify-content: flex-end;
}

.removeStaffButton i {
  margin-left: 5px;
  margin-top: 5px;
}

.companyAddress {
  margin-bottom: 10px;
}

.companyAddress .item2 {
  width: 100%;
}

.companyAddress h2 {
  margin-top: 70px;
  margin-bottom: 0px;
  font-size: 24px;
  font-family: "Segoe Ui", sans-serif;
  font-weight: 300;
  text-transform: uppercase;
  letter-spacing: 1px;
  text-align: left;
}

.newAddresses .item1 {
  width: 95%;
  margin-bottom: 10px;
}

.mtContactName {
  margin-bottom: 20px;
}

.mtContactName input {
  width: 100%;
}

.mtContactSurname {
  margin-bottom: 20px;
}

.mtContactSurname input {
  width: 100%;
}

.mtContactNumber {
  margin-bottom: 20px;
}

.mtContactNumber input {
  width: 100%;
}

.mtContactEmail {
  margin-bottom: 50px;
}

.mtContactEmail input {
  width: 100%;
}

.faContactName {
  margin-bottom: 20px;
}

.faContactName input {
  width: 100%;
}

.faContactSurname {
  margin-bottom: 20px;
}

.faContactSurname input {
  width: 100%;
}

.faContactNumber {
  margin-bottom: 20px;
}

.faContactNumber input {
  width: 100%;
}

.faContactEmail {
  margin-bottom: 50px;
}

.faContactEmail input {
  width: 100%;
}

.customerBusiness label {
  width: 100%;
}

.customerBusiness {
  margin-bottom: 20px;
}

.addAddressBtn {
  cursor: pointer;
  margin-top: 10px;
  font-size: 14px;
  text-transform: uppercase;
  color: #112579;
}

.addAddressBtn:hover {
  transition: all ease 0.8s;
  background-color: #fff;
  color: #000;
  text-decoration: none !important;
}

.removeAddressBtn {
  background-color: #112579;
  color: #fff;
  padding: 10px 15px;
  border: 1px solid #000;
  cursor: pointer;
  transition: all ease 0.8s;
}

.removeAddressBtn:hover {
  background-color: #fff;
  color: #000;
}

.formButtons #submit {
  background-color: #112579;
  color: #fff;
  border: 1px solid #000;
  padding: 15px 55px;
  margin-right: 30px;
  cursor: pointer;
  font-size: 16px;
  text-transform: uppercase;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  letter-spacing: 1px;
}

.formButtons #submit:hover {
  background-color: #f8f8f8;
  border: 1px solid #000;
  color: #000;
  cursor: pointer;
  transition: all ease 0.8s;
}

.formButtons #reset {
  background-color: #f8f8f8;
  color: #000;
  border: 1px solid #000;
  padding: 15px 55px;
  border-radius: 5px;
  margin-right: 30px;
  cursor: pointer;
  font-size: 16px;
  text-transform: uppercase;
  font-family: "Segoe Ui", sans-serif;
  font-weight: 400;
  letter-spacing: 1px;
}

.formButtons #reset:hover {
  background-color: #000;
  border: 1px solid #000;
  color: #fff;
  cursor: pointer;
  transition: all ease 0.8s;
}



.fileDownload button {
  background-color: #fff;
  color: #000;
  border: none;
  padding: 15px;
  padding-left: none;
  margin-right: 30px;
  cursor: pointer;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  letter-spacing: 1px;
}

.fileDownload i {
  margin-left: 10px;
}

.fileDownload #submit:hover {
  background-color: #f8f8f8;
  border: 1px solid #000;
  color: #000;
  cursor: pointer;
  transition: all ease 0.8s;
}



.kb-editableForm input {
  outline: none;
  border: none;
  font-size: 15px;
  color: #000;
  border: 1px solid #e4e4e4;
  padding: 8px 8px;
}

.kb-editableForm input:hover {
  background-color: #fbfbfb;
  color: #000;
  border: 1px solid #e4e4e4;
  transition: all ease 0.8s;
}

.kb-editableForm input:focus {
  background-color: #efefef;
  color: #000;
  border: 1px solid #e4e4e4;
}

.tickets-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.editable-ticket-form {
  margin: 80px;
  width: 80%;
}

.form-title h1 {
  font-family: "Segoe Ui", sans-serif;
  font-weight: 300;
  letter-spacing: 1px;
}

.form-title hr {
  width: 35%;
}

label span {
  margin-right: 10px;
  font-weight: 300;
}

.submittedCompanyForm {
  margin: 80px;
  width: 70%;
}

.submittedCompanyOutput {
  margin-right: 10px;
  font-weight: 300;
}

.submittedCompanyFormItems {
  margin-bottom: 40px;
}

.submittedCompanyFormItems hr {
  margin-bottom: 35px;
}

.staff-form-title {
  margin-top: 80px;
}

.staff-form-title hr {
  width: 35%;
}

.editableCompanyForm {
  margin: 80px;
  width: 75%;
}

.editableCompanyForm input {
  padding: 10px 10px;
}

.editableFormItems {
  margin-bottom: 40px;
}

.editableFormItems input {
  outline: none;
  border: none;
  font-size: 15px;
  color: #000;
  border: 1px solid #e4e4e4;
  padding: 8px 8px;
}

.editableFormItems input:hover {
  background-color: #fbfbfb;
  color: #000;
  border: 1px solid #e4e4e4;
  transition: all ease 0.8s;
}

.editableFormItems input:focus {
  background-color: #efefef;
  color: #000;
  border: 1px solid #e4e4e4;
}

.editableFormItems #submit {
  padding: 15px 10px;
  background-color: #112579;
  border: 1px solid #112579;
  border-radius: 0px;
  color: #fff;
  transition: all 0.8s ease;
  font-weight: bold;
  cursor: pointer;
  width: 30%;
  margin-top: 40px;
}

.editableFormItems #submit:hover {
  background-color: #f8f8f8;
  border: 1px solid #000;
  color: #000;
  cursor: pointer;
}

.editableFormItems #reset {
  padding: 15px 10px;
  background-color: #555;
  border: none;
  border-radius: 0px;
  border: 1px solid #000;
  color: #fff;
  transition: all 0.8s ease;
  font-weight: bold;
  cursor: pointer;
  margin: 10px;
  margin-right: 0;
  width: 30%;
}

.submittedKBOutput {
  margin-bottom: 10px;
}

.submittedKBFormItems #submit {
  padding: 15px 10px;
  background-color: #112579;
  border: 1px solid #112579;
  border-radius: 0px;
  color: #fff;
  transition: all 0.8s ease;
  font-weight: bold;
  cursor: pointer;
  width: 30%;
}

.submittedKBFormItems #submit:hover {
  background-color: #f8f8f8;
  border: 1px solid #000;
  color: #000;
  cursor: pointer;
}

.createTicketForm {
  display: flex;
  flex-direction: row;
}

.kbSearchBar {
  margin-top: 80px;
}

.searchLabel {
  display: flex;
}

.kbsearchBarTitle {
  margin-bottom: 40px;
}

.searchLabel input {
  padding: 10px 10px;
  flex-grow: 1;
}

.searchLabel i {
  background-color: #112579;
  color: #fff;
  padding: 12px;
  cursor: pointer;
  transition: all ease 0.8s;
}

.searchLabel i:hover {
  background-color: #0c1a53;
  color: #fff;
  padding: 12px;
}

.table td,
.table th {
  padding: 0.75rem;
  vertical-align: middle;
  border-top: 1px solid #dee2e6;
}

.activityName {
  margin-bottom: 20px;
}

.activityName input {
  width: 100%;
}

.activityAssigned {
  margin-bottom: 20px;
}

.activityAssigned input {
  width: 100%;
}

.newActivity .activityNotes {
  margin: 20px 0px 20px 0px;
}

.newActivity textarea {
  height: 200px;
}

.newActivity input {
  flex-grow: 1;
}

.activityStarted {
  margin-bottom: 40px;
}

.activityStarted label {
  width: 100%;
}

.activityCompleted {
  margin-bottom: 40px;
}

.activityCompleted label {
  width: 100%;
}

.newActivity .fileUpload {
  margin: 20px 0px 50px 0px;
}

.MuiAppBar-colorPrimary {
  color: #000 !important;
  background-color: #fff !important;
}

.MuiTab-textColorInherit.Mui-selected {
  opacity: 1;
  background-color: #112579 !important;
  color: #fff !important;
  font-weight: 400;
  letter-spacing: 1px;
}

.MuiTabs-flexContainer {
  display: flex;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2) !important;
}

.PrivateTabIndicator-colorSecondary-90 {
  background-color: #112579 !important;
}

.PrivateTabIndicator-colorSecondary-3 {
  background-color: #112579 !important;
}

.ticketActivitiesPage {
  margin: 80px;
}

.ticketActivitiesPage button {
  margin: 20px 0px 20px 0px;
}

.jss264 {
  background-color: #112579;
}


.feedbackForm {
  margin: 15%;
  margin-top: 50px !important;
}

.feedbackForm h1 {
  text-align: center;
}

.ratings {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 40px;
  margin-bottom: 40px;
}

.ratings button {
  background-color: #fff;
  border: none;
}

.formarea {
  margin-bottom: 40px;
}

.formarea h4 {
  font-family: "Segoe UI";
  font-weight: 300;
  text-transform: uppercase;
  margin-bottom: 20px !important;
  font-size: 22px;
}

.MuiFormGroup-root {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row !important;
  justify-content: space-between !important;
  align-items: center !important;
  width: 100%;
}

.MuiFormGroup-root img {
  width: 100% !important;
}

.MuiInputBase-input {
  font: inherit;
  color: currentColor;
  width: 100%;
  border: 0;
  height: 1.1876em;
  margin: 0;
  display: block;
  padding: 6px 0 7px;
  min-width: 0;
  background: none;
  box-sizing: content-box;
  animation-name: mui-auto-fill-cancel;
  letter-spacing: inherit;
  animation-duration: 10ms;
}

.editableKBFormItems .MuiInputLabel-formControl {
  top: 0;
  left: 0;
  position: absolute;
  transform: translate(0, 24px) scale(1);
  color: #fff !important;
}

.editableTicketFormsItems .MuiInputLabel-formControl {
  top: 0;
  left: 0;
  position: absolute;
  transform: translate(0, 24px) scale(1);
  color: #fff !important;
}

.MuiInputBase-root {
  width: 35%;
}

.MuiFormControl-root {
  border: 0;
  margin: 0;
  display: inline-flex;
  padding: 0;
  position: relative;
  min-width: 95% !important;
  flex-direction: column;
  vertical-align: top;
}

.MuiFormHelperText-root {
  color: #f00 !important;
  margin: 0;
  font-size: 0.75rem;
  margin-top: 3px;
  text-align: left;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1.66;
  letter-spacing: 0.03333em;
}



.search-form {
  margin-top: 40px;
}

.search-form input {
  width: 100%;
}

.search-form .container {
  padding: none !important;
}

/* React Scheduler Component */

@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,900;1,100;1,300;1,400;1,900&display=swap");
.App {
  text-align: center;
}

.action-buttons {
  margin-top: 18px;
  float: right;
}

.block-alt {
  margin-top: 0px !important;
  padding: 20px !important;
  margin-bottom: 30px;
}

#owner-group-select {
  background: white;
  padding: 10px;
  margin-top: 20px;
}

.schedule-input {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}
.template-select {
  padding: 10px;
  width: 100%;
}

.login-home-link {
  color: black;
}

.widget {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.15);
}

.login-home-link:hover {
  text-decoration: none;
}

.action-buttons button {
  margin-left: 10px;
}

.step-active-abc {
  background-color: white !important;
  color: black !important;
}

.map-container {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.sidebarStyle {
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  margin: 12px;
  background-color: #404040;
  color: #ffffff;
  z-index: 1 !important;
  padding: 6px;
  font-weight: bold;
}

.schedule-box {
  background-color: white;
  width: 100%;
  /* height: 100%; */
  position: absolute;
  top: 200px;
  padding: 25px;
  z-index: 999;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
    0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  height: 100%;
  min-height: 480px;
}

.page-header {
  width: 100%;
  height: 55px;
  background: white;
  position: absolute;
  left: 0;
}

.header-item-title {
  color: white;
  margin: 0px;
  font-size: 22px;
  margin-top: 16px;
  text-transform: uppercase;
}

.evidence-title {
  text-align: center;
  background: black;
  color: white;
  padding: 10px;
}
.back-arrow {
  background-color: white;
  padding: 5px;
  border-radius: 20px;
  margin: 0px;
  width: 30px;
  height: 30px;
  justify-content: center;
  align-items: center;
}

.next-arrow {
  float: right;
  margin-right: 30px;
}
.wdt {
  width: 90% !important;
}
.img {
  height: 300px !important;
  width: 100%;
}

.imgdetails {
  height: 280px !important;
  width: 600px !important;
}

.imgpad {
  padding-bottom: 0px !important;
  padding-top: 0px !important;
  padding-left: 50px !important;
  padding-right: 50px !important;
}
.cls {
  padding-right: 300px !important;
  background-color: blue;
}
.page-header-item {
  height: 100px;

  width: 100%;
  margin-bottom: 40px;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
    0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background-size: 400% 400%;
  -webkit-animation: AnimationName 20s ease infinite;
  -moz-animation: AnimationName 20s ease infinite;
  animation: AnimationName 20s ease infinite;
  padding: 15px;
  align-items: center;
  justify-content: center;
}

.padding-sm {
  padding: 10px;
}

.padding-md {
  padding: 20px;
}

.padding-lg {
  padding: 30px;
}

.product-box {
  width: 100%;
  height: 400px;
  overflow-x: hidden;
  overflow-y: scroll;
}

.product-card {
  margin-bottom: 25px;
  padding: 10px;
  transition: 1s ease-in-out;
}

.product-card p {
  text-align: center;
  font-family: "Roboto", sans-serif;
  margin-top: 5px;
  margin-bottom: 5px;
  font-weight: 500;
}

.product-active {
  border: 1px solid black;
  transition: 1s ease-in-out;
}

.stepper {
  text-align: center;
  font-family: "Roboto", sans-serif;
  color: grey;
}

.step-active {
  color: #112479;
}

.step-active p {
  border-bottom: 4px solid #112479;
}

.stepper p {
  margin: 0px;
  font-size: 14px;
}

.menu div:hover {
  cursor: pointer;
  transition: 1s ease-in-out;
  opacity: 0.8;
}

.widget-circle {
  width: 200px;
  height: 200px;
  display: inline-block;
  border: 20px solid rgba(9, 9, 121, 1);
  border-radius: 100%;
  justify-content: center;
  align-items: center;
  vertical-align: sub;
  font-size: 50px;
  font-weight: 500;
  font-family: "Roboto", sans-serif;
  padding-top: 13%;
  margin-top: 1rem;
}

.page-section {
  background: white;
  width: 100%;
  margin-bottom: 40px;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
    0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  align-items: center;
  justify-content: center;
}

.settings-menu {
  margin: 0px;
  padding: 0px;
  font-family: "Roboto", sans-serif;
}

.settings-menu li {
  list-style: none;
  padding-top: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid#f5f5f5;
  transition: 1s ease-in-out;
}

.settings-menu li a {
  color: black;
}

.module-group-container {
  width: 30%;
}

.settings-menu li:hover {
  cursor: pointer;
  background-color: #112579;
  color: white !important;
  transition: 1s ease-in-out;
}

.settings-menu li svg {
  margin-right: 15px;
  margin-left: 30px;
}

.header-btn {
  color: white;
  border: 2px solid white;
  padding: 10px;
  width: 150px;
  border-radius: 40px;
  margin-top: 10px;
  margin-left: 20px;
  text-align: center;
  float: right;
  transition: 1s ease-in-out;
}

.header-btn svg {
  margin-right: 10px;
}

.header-btn:hover {
  cursor: pointer;
  background-color: white;
  color: #112579;
  transition: 1s ease-in-out;
}

.page-header-item ul {
  margin: 0px;
  padding: 0px;
}

.page-header-item ul li {
  display: inline-block;
  list-style: none;
}

.popup {
  background-color: black;
  opacity: 0.5;
  backdrop-filter: blur(5px);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
}

.icon-container {
  float: right;
}

.icon-container ul {
  padding: 0px;
  margin: 0px;
}

.icon-container ul li {
  list-style: none;
  display: inline-block;
  padding-left: 20px;
  padding-right: 20px;
  font-size: 28px;
}

.icon-container ul li:hover {
  cursor: pointer;
}

.pin-btn {
  width: 100%;
  border: 0;
  padding: 15px;
  margin-top: 8px;
  border-bottom-right-radius: 15px;
  border-bottom-left-radius: 15px;
}

.card-container {
  width: 20%;
  height: 200px;
  background-color: white;
  z-index: 9999;
  position: fixed;
  top: 40%;
  left: 50%;
  transform: translate(-40%, -40%);
  border: 1px solid grey;
  border-radius: 15px;
}

#pin {
  width: 355px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.header {
  padding: 10px;
  justify-content: center;
  align-items: center;
  z-index: 999;
  position: fixed;
  width: 100%;
  background: white;
}

.chart {
  height: 600px;
}

.logo {
  width: 40%;
}

.logo-container {
  text-align: center;
}

.content {
  background-color: #ececec;
  margin-top: 60px;
  margin-left: 16.66%;
}

.form-row {
  margin-top: 15px;
  margin-bottom: 15px;
}

h3,
h4 {
  font-family: "Roboto", sans-serif;
  margin: 0px !important;
}

.table-add {
  background: white;
  color: linear-gradient(
    39deg,
    rgba(2, 0, 36, 1) 0%,
    rgba(9, 9, 121, 1) 51%
  ) !important;
  border-radius: 50%;
  border: 2px solid rgba(9, 9, 121, 1);
  padding: 10px;
  font-size: 45px;
  margin-top: 10px;
  margin-left: 15px;
  transition: 1s ease-in-out;
}

.table-remove {
  background: white;
  color: red;
  border-radius: 50%;
  border: 2px solid red;
  padding: 5px;
  font-size: 35px;
  margin-top: 10px;
  margin-left: 15px;
  transition: 1s ease-in-out;
}

.table-remove:hover {
  background: red !important;
  color: white;
  cursor: pointer;
  transition: 1s ease-in-out;
}

.widget-header {
  border-bottom: 1px solid #f1f1f1;
}

.widget-header div {
  padding: 10px;
}

.table-add:hover {
  background: linear-gradient(
    39deg,
    rgba(2, 0, 36, 1) 0%,
    rgba(9, 9, 121, 1) 51%
  ) !important;
  color: white;
  cursor: pointer;
  transition: 1s ease-in-out;
}

.btn-primary {
  background-color: linear-gradient(
    39deg,
    rgba(2, 0, 36, 1) 0%,
    rgba(9, 9, 121, 1) 51%,
    rgba(0, 212, 255, 1) 100%
  ) !important;
}

.block {
  width: 100%;
  background: white;
  margin-top: 20px;
  padding: 10px;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
    0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

div.transition-group {
  position: relative;
}
section.route-section {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
}

.fade-enter {
  opacity: 0.01;
}

.email-btn {
  background: blue;
  border: 0px;
  margin-top: 10px;
  padding: 12px;
  color: white;
}

.email-popup {
  position: absolute;
  top: 20%;
  left: 19%;
  width: 50%;
  z-index: 999;
  background: white;
  padding: 20px;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 400ms ease-in;
}

.fade-exit {
  opacity: 1;
}

.fade-exit.fade-exit-active {
  opacity: 0.01;
  transition: opacity 400ms ease-in;
}

.sidebar-container {
  height: 100vh;
  z-index: 999;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
    0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);

  padding: 20px;
  background-size: 300% 300%;
  -webkit-animation: AnimationName 20s ease infinite;
  -moz-animation: AnimationName 20s ease infinite;
  animation: AnimationName 20s ease infinite;
  position: fixed !important;
  margin-top: 60px;
}

@-webkit-keyframes AnimationName {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
@-moz-keyframes AnimationName {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
@keyframes AnimationName {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.sidebar-container ul {
  padding: 0;
  margin: 0;
}

.sidebar-container ul li {
  list-style: none;
}

.mybe_title {
  margin-bottom: 0px;
  text-transform: uppercase;
  letter-spacing: 10px;
  font-weight: bold;
  color: grey;
}

.profile {
  width: 60px !important;
  border-radius: 100%;
}

.mybe_logo {
  font-weight: bold;
  font-size: 50px;
}

.nav-active div {
  background: white;
  border-radius: 40px;
  color: #112579 !important;
}

.nav {
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 20px !important;
  text-transform: uppercase;
}

.user {
  padding-left: 20px !important;
}

.user li {
  list-style: none;
  display: inline-block;
  margin-right: 20px;
}

.username,
.role {
  margin: 0px;
}

.username {
  color: white;
  font-family: "Roboto", sans-serif;
  font-weight: normal;
  font-size: 15px;
}

.role {
  font-size: 13px;
  color: gainsboro;
}

.divider {
  width: 100%;
  height: 1px;
  background: white;
  margin-top: 25px;
  margin-bottom: 5px;
}

.sidebar-container ul li span {
  margin-left: 25px;
}

.sidebar-container ul li a {
  text-decoration: none;
  font-size: 14px;
  font-family: "Roboto", sans-serif !important;
}

.recharts-wrapper {
  position: absolute !important;
  top: -118px;
  margin-left: 80px;
}

.lockscreen-container {
  position: absolute;
}

.stats-container {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
    0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  width: 100%;
  background: white;
  padding: 10px;
  height: 200px;
  overflow: hidden;
}

.login-btn {
  width: 350px;
  padding: 14px;
  border-radius: 50px;
  border: 0px;
  color: white;
  background: #112479;
}

.sidebar-container ul li a svg {
  font-size: 20px;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.login-screen {
  text-align: center;
  margin-top: 10%;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
